import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@urbansportsclub/components/Text';
import { Alert } from '@urbansportsclub/components/Alert';
import { AlertTitle } from '@urbansportsclub/components/Alert/Title';
import { AlertContent } from '@urbansportsclub/components/Alert/Content';
import { AlertActions } from '@urbansportsclub/components/Alert/Actions';
import { AlertAction } from '@urbansportsclub/components/Alert/Action';

interface IInfoBlockProps {
  title: string,
  content: string,
  btnText: string,
  onClick: () => void,
}

const InfoBlock = ({
  title,
  content,
  btnText,
  onClick,
} : IInfoBlockProps) => {
  const { t } = useTranslation();
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    const element = textContainerRef.current;
    if (element) {
      setIsOverflowing(element.scrollHeight > element.clientHeight);
    }
  };

  useEffect(() => {
    checkOverflow();

    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
    
  }, []);

  return (
    <Alert severity='info' showCloseButton={false} className='p-4'>
      <AlertTitle className='mb-1.5 text-blue-respect-700'>
        {title}
      </AlertTitle>
      <AlertContent>
        <div 
          ref={textContainerRef} 
          className={`relative text-base text-wrap ${isExpanded ? '' : 'line-clamp-2'}`}
          style={{ maxHeight: isExpanded ? 'none' : '3.6em', overflow: 'hidden' }}
        >
          <Text as='span' intent='sm' className='text-base text-wrap text-blue-respect-600'>
            {content}
            {isExpanded && (
              <span className="pl-1">
                <button onClick={() => setIsExpanded(!isExpanded)} className="underline text-base font-bold text-blue-respect-600">
                  {t('action.show_less')}
                </button>
              </span>
            )}
          </Text>
          {isOverflowing && !isExpanded && (
            <span className="absolute bottom-0 right-0 bg-blue-respect-50 pl-2 text-blue-respect-600">
              {'... '}
              <button onClick={() => setIsExpanded(!isExpanded)} className="underline text-base font-bold text-blue-respect-600">
                {t('action.show_more')}
              </button>
            </span>
          )}
        </div>
      </AlertContent>
      <AlertActions>
        <AlertAction className='border-none p-0 h-[unset] text-blue-respect-700 text-base'><span onClick={onClick}>{btnText}</span></AlertAction>
      </AlertActions>
    </Alert>
  );
};

export default InfoBlock;
