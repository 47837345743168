import { gql } from '@apollo/client';

import { Employee } from 'models/employee';
import { PaginationInfo } from 'models/generic';

export interface EmployeeData {
  employee: Employee
}

export interface EmployeesData {
  employees: {
    collection: Array<Employee>;
    paginationInfo: PaginationInfo;
  }
}

export const GET_EMPLOYEES = gql`
  query GetEmployees(
    $membershipState: [String!]!,
    $companyId: String!,
    $search: String,
    $page: Int,
    $itemsPerPage: Int,
    $order: [EmployeeFilter_order],
    $emails: String,
  ) {
    employees(
      membership_state_list: $membershipState,
      company_id: $companyId,
      search: $search,
      page: $page,
      itemsPerPage: $itemsPerPage,
      order: $order,
      emails: $emails,
    ) {
      collection {
        id,
        firstName,
        lastName,
        email,
        membershipCityName,
        membershipStartDate,
        membershipPlanTypeName,
        membershipState,
        membershipIntegrationState,
        membershipStateActions,
        membershipId,
        isSynced,
      },
      paginationInfo {
        itemsPerPage,
        lastPage,
        totalCount
      }
    }
  }
`;
