import { ReactNode, useState } from 'react';

import Header from 'components/organisms/Header';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';
import { ITab, ITabsState } from 'models/generic';
import SideBarMenu from 'components/organisms/SideBarMenu/SideBarMenu';
import UserRolesSelection from 'components/organisms/UserRolesSelection/UserRolesSelection';

interface Props {
  children: ReactNode;
  heading: string;
  search?: boolean;
  onSearchHandler?: React.ChangeEventHandler<HTMLInputElement>;
  loading?: boolean;
  className?: string;
  headerButtonText?: string;
  headerButtonOnClick?: React.MouseEventHandler;
  tabs?: ITabsState;
  tabClickHandler?: (activeTab: string) => void;
  tabTranslationKeyGroup?: string;
  badges?: string[];
  onRemove?: (value: string) => void;
  searchInputValue?: string;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  subHeading?: string;
  showDownloadList?: boolean;
  showContactUs?: boolean;
}

const SidebarLayout = ({
  children,
  heading,
  search = false,
  onSearchHandler,
  loading = false,
  className = '',
  headerButtonText,
  headerButtonOnClick,
  tabs,
  tabClickHandler,
  tabTranslationKeyGroup,
  badges,
  onRemove,
  searchInputValue,
  onPaste,
  subHeading,
  showDownloadList,
  showContactUs = false,
}: Props): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onMenuToggle = () => setIsMenuOpen(!isMenuOpen);
  return (
    <div>
      <div className='"hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"'>
          <SideBarMenu />
      </div>
      <main className="pt-10 lg:pl-72">
        <UserRolesSelection isModal />
        <div className='-mt-10'>
          <Header
            heading={heading}
            search={search}
            onSearchHandler={onSearchHandler}
            onToggle={onMenuToggle}
            isMenuOpen={isMenuOpen}
            buttonText={headerButtonText}
            buttonOnClick={headerButtonOnClick}
            tabs={tabs}
            tabClickHandler={tabClickHandler}
            tabTranslationKeyGroup={tabTranslationKeyGroup}
            loading={loading}
            badges={badges}
            onRemove={onRemove}
            searchInputValue={searchInputValue}
            onPaste={onPaste}
            subHeading={subHeading}
            showDownloadList={showDownloadList}
            showContactUs={showContactUs}
          />
        </div>
        <div className="px-4 sm:px-6 lg:px-8">
          {
            loading ? <LoadingIcon className="fixed-sidebar-layout__loading-icon rotating" role="img" /> : children
          }
        </div>
      </main>
    </div>
  );
};

export default SidebarLayout;
