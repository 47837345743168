import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog as TailwindDialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '@urbansportsclub/components/Button';
import { Loader } from '../Loader/Loader';
import * as styles from './Dialog.styles';

interface IDialogProps {
  open: boolean;
  title: string;
  titleVariant?: 'default' | 'close';
  description?: string;
  onCancel? : () => void;
  onConfirm? : () => void;
  onCancelText?: string;
  onConfirmText?: string;
  loading?: boolean;
  variants?: 'primary' | 'secondary' | 'danger';
  children?: React.ReactNode;
  disabled?: boolean;
  badge?: 'primary' | 'secondary' | 'danger';
  showFooter?: boolean;
}

export default function Dialog({ open = false, title, titleVariant = 'default', description, onCancel, onConfirm, onCancelText, onConfirmText, loading, variants = 'danger', children, disabled, badge, showFooter = true } : IDialogProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(open);

  useEffect(() => { setIsDialogOpen(open); }, [open]);

  const cancelButtonRef = useRef(null);

  const handleClose = () => {
    setIsDialogOpen(false);
    if (onCancel) onCancel();
  };

  return (
    <Transition.Root show={isDialogOpen} as={Fragment}>
      <TailwindDialog className="relative z-40" initialFocus={cancelButtonRef} onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <TailwindDialog.Panel className="relative p-4 md:p-8 transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg max-h-[85vh]">
                <div>
                  {badge === 'danger' && <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-passion-50">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>}
                  {badge === 'primary' && <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-freedom-50">
                    <CheckIcon className="h-6 w-6 text-green-freedom-600" aria-hidden="true" />
                  </div>}
                  { titleVariant === 'default' ? (
                    <div className="mt-3 text-center sm:mt-5">
                      <TailwindDialog.Title as="h3" className="text-xl font-bold leading-[26px] text-black-midnight-light-800">
                        {title}
                      </TailwindDialog.Title>
                      <div className="mt-2">
                        <p className="text-base text-black-midnight-500">
                          {description}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-4 mt-2 text-left flex justify-between">
                      <TailwindDialog.Title as="h3" className="text-2xl font-bold text-black-midnight-light-800">
                        {title}
                      </TailwindDialog.Title>
                      <XMarkIcon className='w-6 h-6 text-grey-dark-500 cursor-pointer' onClick={onCancel}/>
                    </div>
                  )}
                </div>
                <div
                  className='scrollbar overflow-y-auto pr-[12px] mr-[-12px] max-h-[calc(85vh-100px)]'
                >
                  {children}
                  <div className={styles.footerDiv({ cols: onCancel ? true : false, showFooter: showFooter })}>
                    {onCancel && <Button variant='secondary' onClick={onCancel} disabled={loading}>
                     {onCancelText ?? 'Cancel'}
                    </Button>}
                    <Button variant={variants} onClick={onConfirm} disabled={loading || disabled} className='gap-x-2'>
                       {loading && <Loader />}  {onConfirmText ?? 'Deactivate'}
                    </Button>
                  </div>
                </div>
              </TailwindDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </TailwindDialog>
    </Transition.Root>
  );
}