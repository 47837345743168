import { tv } from 'tailwind-variants';

export const footerDiv = tv({
  base: 'flex flex-col mt-5 gap-3 sm:mt-6 sm:grid sm:grid-flow-row-denses sm:flex-row',
  variants: {
    cols: {
      true: 'sm:grid-cols-2 sm:gap-3',
    },
    showFooter: {
      true: 'flex sm:grid',
      false: '!hidden',
    },
  },
});
