import React, { useLayoutEffect, useRef, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid';
import { ButtonVariants }  from 'components/atoms/Tailwind/Button';
import { Button } from '@urbansportsclub/components/Button';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';
import ReactPaginate from 'react-paginate';
import { Dropdown } from '@urbansportsclub/components/Dropdown';
import { DropdownContent } from '@urbansportsclub/components/Dropdown/Content';
import { DropdownItem } from '@urbansportsclub/components/Dropdown/Item';
import { DropdownTrigger } from '@urbansportsclub/components/Dropdown/Trigger';
import { Tooltip } from '@urbansportsclub/components/Tooltip';
import { TooltipContent } from '@urbansportsclub/components/Tooltip/Content';
import { TooltipTrigger } from '@urbansportsclub/components/Tooltip/Trigger';
import * as styles from './Table.styles';

export enum ColumnTypes {
  CHECKBOX = 'checkbox',
  BUTTON = 'button',
}

export enum Variants {
  PRIMARY = 'base',
  SECONDARY = 'secondary',
}

export interface TableColumn {
  name: string;
  render?: (row:object) => JSX.Element;
  key: string;
  type?: ColumnTypes;
  onClick?: (row: object) => void;
  variant?: Variants;
  isHidden?: any;
  className?: string;
}

interface Row {
  [key: string]: any;
}

interface Sort {
  value: string;
  label: string;
}

interface TableProps {
  columns: TableColumn[];
  rows: object[];
  onRowClick?: (row: object) => void;
  sortable?: boolean;
  onSortChange?: (value:string) => void;
  sortyBy?: Sort[];
  sortedColumn: any;
  pagination?: {
    page: number;
    itemsPerPage: number;
    totalItems: number;
    nextLabel?: string | React.ReactNode;
    previousLabel?: string | React.ReactNode;
    pageCount: number;
    onPageChange?: (page: number) => void;
    itemsPerPageOptions?: string[];
    onChangeItemsPerPage?: (itemsPerPage: string) => void;
    paginationInfo?: string;
    onNextPage?: () => void;
    onPreviousPage?: () => void;
    currentPage?: number;
  },
  bulkOptions?: {
    allowBulkEdit?: boolean;
    editButton?: boolean;
    editButtonTextSingle: string;
    editButtonText: string;
    editButtonOnClick?: (selectedRows:string[]) => void;
    allowBulkButtonText?: string;
    onAllowBulkButtonClick?: () => void;
    alwaysVisibleCheckbox?: boolean;
    selectedButtonColor?: ButtonVariants;
  },
  isLoading?: boolean;
  selectedRows?: string[];
  setSelectedRows?: (selectedRows: string[]) => void;
}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Table({ 
  columns,
  rows,
  sortyBy,
  onSortChange,
  sortable,
  sortedColumn,
  pagination,
  bulkOptions,
  isLoading,
  selectedRows,
  setSelectedRows,
}: TableProps): JSX.Element {
  const checkbox = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [isSelectMultiple, setIsSelectMultiple] = useState(bulkOptions?.alwaysVisibleCheckbox ?? false);

  useLayoutEffect(() => {
    const getSelectedRowsLayout = () => selectedRows ?? [];
    const isIndeterminate = getSelectedRowsLayout().length > 0 && getSelectedRowsLayout().length < rows.length;
    setChecked(getSelectedRowsLayout().length === rows.length);
    setIndeterminate(isIndeterminate);
    // checkbox.current.indeterminate = isIndeterminate;
  }, [selectedRows, rows]);

  function toggleAll() {
    setSelectedRows && setSelectedRows(checked || indeterminate ? [] : rows.map((row) => getDataForSelection(row, columns[0].key)));
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function getDataForSelection(row: Row, key:string): string {
    return String(row[key]);
  }

  function getSelectedRows() {
    return selectedRows ?? [];
  }

  function handleSort(columnKey: string) {
    if (columnKey === 'isSynced') {
      const direction = sortedColumn.direction === 'asc' ? 'desc' : 'asc';
      return onSortChange?.(`hriEmployeeId ${direction}`);
    }
  
    if (columnKey === 'name') {
      const direction = sortedColumn.direction === 'asc' ? 'desc' : 'asc';
      return onSortChange?.(`firstName ${direction},lastName ${direction}`);
    }
  
    const isSameColumn = sortedColumn.columnName === columnKey;
    const newDirection = isSameColumn && sortedColumn.direction === 'asc' ? 'desc' : 'asc';
    
    onSortChange?.(`${columnKey} ${newDirection}`);
  }

  function mapColumnToRow(column: TableColumn, row: Row, index:number): JSX.Element {
    if (column.type === ColumnTypes.CHECKBOX) {
      return (
        isSelectMultiple ? 
        <td key={index} className='relative px-3.5 md:px-6 sm:w-12 sm:px-4'>
          <input
            type='checkbox'
            className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-respect-600 focus:ring-blue-respect-600'
            value={getDataForSelection(row, column.key)}
            checked={getSelectedRows().includes(getDataForSelection(row, column.key))}
            onChange={(e) => setSelectedRows && setSelectedRows(
              e.target.checked
                ? [...getSelectedRows(), getDataForSelection(row, column.key)]
                : getSelectedRows().filter((p) => p !== getDataForSelection(row, column.key)),
            )} />
        </td> 
          : <></>
      );
    } else if (column.type === ColumnTypes.BUTTON) {
      return (
        <td key={index} className='whitespace-nowrap py-1.5 text-md font-medium mx-6 text-right text-gray-900'>
         { column.render ? 
           column.render(row) :
           <Button className='bg-transparent mx-6 sm:mr-12 px-2' variant={'link'} onClick={() => ( column.onClick ? column.onClick(row) : null )}>{column.name}</Button>
          }
        </td>
      );
    }

    return (
      <td
        key={index}
        className={classNames('whitespace-nowrap py-1.5 text-md font-medium mx-4 h-[64px]', column.className,
          getSelectedRows().includes(getDataForSelection(row, column.key)) ? 'text-blue-respect-600' : 'text-gray-900',
        )}
      >
        { column.render ? column.render(row)  : row[column.key] }
      </td>
    );
  }

  function showSort(column: TableColumn) {
    return (
      column.key === sortedColumn.columnName || 
      (column.key === 'name' && sortedColumn.columnName === 'firstName') ||
      (column.key === 'isSynced' && sortedColumn.columnName === 'hriEmployeeId')
    );
  }

  function mapColumn(column:TableColumn, index:number): JSX.Element {
    if (column.type === ColumnTypes.CHECKBOX) {
      return (
        isSelectMultiple ? 
        <th key={index} scope='col' className='relative px-3.5 md:px-6 sm:w-12 sm:px-4'>
          <input
            type='checkbox'
            className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-respect-600 focus:ring-blue-respect-600'
            ref={checkbox}
            checked={checked}
            onChange={toggleAll}
          />
        </th> 
          : <></>
      );
    } else if (column.type === ColumnTypes.BUTTON) {
      return (
        <th key={index} scope='col' className={`${column.className} text-right pr-3 pl-2 sm:pr-6 sm:pl-4 py-2 text-base font-normal text-gray-900 cursor-pointer`}>
          <span>Manage User</span>
        </th>
      );
    }

    if (column.key === 'name' && getSelectedRows().length > 0) {
      const btnText = getSelectedRows().length > 1 ? bulkOptions?.editButtonText : bulkOptions?.editButtonTextSingle;

      return (
        <th
          key={index}
          scope="col"
          className={`${column.className} px-4 py-2 text-base font-normal text-gray-900 cursor-pointer h-[48px]`}
        >
          <Button
            onClick={() => (bulkOptions?.editButtonOnClick ? bulkOptions.editButtonOnClick(getSelectedRows()) : null)}
            variant={bulkOptions?.selectedButtonColor ? bulkOptions.selectedButtonColor : ButtonVariants.PRIMARY}
            small
          >
            {`${btnText} (${getSelectedRows().length})`}
          </Button>
        </th>
      );
    }

    return (
      <th
        key={index}
        onClick={() => handleSort(column.key)}
        scope="col"
        className={`${column.className} px-4 py-2 text-base font-normal text-gray-900 cursor-pointer h-[48px]`}
      >
        {
          showSort(column) ? (
            <div className='gap-2 items-center flex'>
                <span>{column.name}</span>
                <div className='w-[16px] h-[16px]'>{sortedColumn.direction === 'asc' ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
            </div>
          ) : (
            <Tooltip className='w-fit mr-auto'>
              <TooltipTrigger>
                  <div className='flex gap-2 items-center'>
                    <span>{column.name}</span>
                    {showSort(column) && (
                      <div className='w-[16px] h-[16px]'>{sortedColumn.direction === 'asc' ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
                    )}
                  </div>
              </TooltipTrigger>
              <TooltipContent placement='right-start' className='text-base text-wrap p-1 !left-[100%] transform !translate-y-0 !top-[0px] after:!hidden !bg-transparent'>
                <div className='w-[16px] h-[16px]'><ArrowDownIcon className='text-grey-dark-500' /></div>
              </TooltipContent>
            </Tooltip>
          )
        }
      </th>
    );
  }

  function renderSmallPagination() {
    if ((pagination?.pageCount as number) > 7) return null;

    const itemList: any = [];  
    for (let i = 1; i <= (pagination?.pageCount as number); i++) {  
      itemList.push(
        <li className={styles.paginationItem({ isActive: i === pagination?.page, isFirst: i === 1, isLast: i === pagination?.pageCount })}>
          <span className="py-2 px-4 inline-block w-[100%] h-[100%]" onClick={() => pagination?.onPageChange?.(i)}>{i}</span>
        </li>,
      );  
    }  
  
    return <ul className='flex justify-center'>{itemList}</ul>; 
  }

  function renderBigPagination() {
    if ((pagination?.pageCount as number) <= 7) return null;

    return (
      <ReactPaginate
        breakLabel="..."
        breakClassName="three-dots-classname ring-1 ring-grey-dark-300 bg-white-natural-500"
        breakLinkClassName="three-dots-a-classname py-2 px-4 inline-block w-[100%] h-[100%]"
        pageCount={pagination?.pageCount as number}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName="flex justify-center"
        pageClassName="li-classname text-base text-black-midnight-light ring-1 ring-grey-dark-300 bg-white-natural-500 focus:ring-blue-respect-500 focus:rounded-md"
        pageLinkClassName="a-classname py-2 px-4 inline-block w-[100%] h-[100%]"
        activeClassName="li-classname-active font-semibold text-white !bg-blue-respect-500"
        previousLabel={<ChevronLeftIcon className='w-5 h-5 text-grey-dark-500' aria-hidden="true" />}
        previousClassName={styles.paginationArrow({ isLeft: true, isDisabled: pagination?.page === 1 })}
        previousLinkClassName="flex flex items-center justify-center w-[100%] h-[100%]"
        nextLabel={<ChevronRightIcon className='w-5 h-5 text-grey-dark-500' aria-hidden="true" />}
        nextClassName={styles.paginationArrow({ isLeft: false, isDisabled: pagination?.page === pagination?.pageCount })}
        nextLinkClassName="flex flex items-center justify-center w-[100%] h-[100%]"
        // eslint-disable-next-line no-unused-vars
        hrefBuilder={(page, pageCount, selected) =>
          page >= 1 && page <= pageCount ? `/page/${page}` : '#'
        }
        hrefAllControls
        forcePage={(pagination?.page as number) - 1}
      />
    );
  }

  function handlePageClick(data : any) {
    pagination?.onPageChange?.((data.selected as number) + 1);
  }

  return (
    isLoading ? <LoadingIcon className="fixed-sidebar-layout__loading-icon rotating" role="img" /> : <div className='flow-root'>
      <div className='-mx-4 -my-2 contain-paint sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle px-4 sm:px-6 lg:px-10'>
          <div className='relative border border-gray-200 rounded-xl contain-paint'>
              <table className='min-w-full sm:table-fixed divide-y divide-gray-200 w-[100%]'>
                <thead>
                  <tr className='bg-grey-light-500 sticky top-[0] z-[100]'>
                    {
                      columns.map((column, index) => mapColumn(column, index))
                    }
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {
                    rows.map((row, index) => (
                      <tr key={index} className={classNames(getSelectedRows().includes(getDataForSelection(row, '')) ? 'bg-gray-50' : undefined, 'border-l !border-l-white border-l-2 hover:!border-l-blue-respect-500 hover:bg-grey-dark-50')}>
                        {columns.map((column, columnIndex) => mapColumnToRow(column, row, columnIndex))}
                      </tr>
                    ))
                  }
                </tbody>
              </table>
          </div>
        </div>
      </div>
      <div className="md:hidden flex justify-between py-3">
        <Button className="text-base" variant="secondary" onClick={pagination?.onPreviousPage} disabled={pagination?.page === 1}>Previous</Button>
        <Button className="text-base" variant="secondary" onClick={pagination?.onNextPage} disabled={pagination?.page === pagination?.pageCount}>Next</Button>
      </div>
      <div className="hidden md:flex items-center justify-between py-3 px-3 flex-row">
        <div className="flex flex-1 items-center justify-between">
          <div className=''>
            <p className="text-sm text-gray-700">
              {pagination?.paginationInfo}
            </p>
          </div>
          <div>
            {renderBigPagination()}
            {renderSmallPagination()}
          </div>
          <Dropdown
            value={(pagination?.itemsPerPage as number)?.toString()}
            variant="combobox"
            mode="light"
            onChange={(value) => pagination?.onChangeItemsPerPage?.(value)}
          >
            <DropdownTrigger className="w-full inline-flex justify-between items-center text-left py-1 pl-4 pr-1">
              {(pagination?.itemsPerPage as number)?.toString()}
            </DropdownTrigger>
            <DropdownContent side="top" className="z-10 content-stretch justify-start min-w-1">
              {pagination?.itemsPerPageOptions?.map((item) => (
                <DropdownItem key={item} value={item} className='py-2 px-3 [&>svg]:hidden'>
                  {item}
                </DropdownItem>
              ))}
            </DropdownContent>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}