import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Modal from 'components/molecules/Modal';
import Popup from 'components/molecules/Popup';
import Radio from 'components/atoms/Radio';
import Input from 'components/atoms/Input';
import Separator from 'components/atoms/Separator';
import Button, { ButtonType, ButtonSize } from 'components/atoms/Button';
import CellItem from 'components/atoms/CellItem';
import { formatDate } from 'utils/date';
import { EmployeeData } from 'utils/graphql/queries/employee';
import { EmployeeMembershipCancelationOptions, EmployeeStateActions } from 'models/employee';
import { DataLayerEvents, EVENT_GROUP_NAME, pushDataLayerEvent } from 'utils/dataLayer';
import { useCancelMembership } from 'services/membershipService';
import axios from 'axios';
import { getRequestConfig } from 'utils/api/requestConfig';
import CustomButton, { ButtonSize as CustomButtonSize, ButtonVariants as CustomButtonVariants } from '../../atoms/Tailwind/Button';
import Dialog from 'components/atoms/Tailwind/Diaglog/Dialog';
import { Button as USCButton } from '@urbansportsclub/components/Button';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  membershipCancelationStarted: { event_group: EVENT_GROUP_NAME, event_name: 'membership_cancelation_started' },
  membershipCancelationReasonSelected: { event_group: EVENT_GROUP_NAME, event_name: 'membership_cancelation_reason_selected' },
  membershipCancelationConfirmed: { event_group: EVENT_GROUP_NAME, event_name: 'membership_cancelation_confirmed' },
};

enum CancellationSteps {
  CLOSE = 0,
  REASON = 1,
  DATE = 2,
}

interface Props {
  detailsData: EmployeeData | undefined,
  detailsLoading: boolean;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  statusChangeLoading: boolean;
  onEmployeeApprove: (arg: string) => void,
  onEmployeeDecline: (arg: string) => void,
  updateListHandler: () => void,
}

const EmployeeDetails = ({
  detailsData,
  detailsLoading,
  isOpen,
  setIsOpen,
  statusChangeLoading,
  onEmployeeApprove,
  onEmployeeDecline,
  updateListHandler,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [cancellationStep, setCancellationStep] = useState(CancellationSteps.CLOSE);
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const { cancelRequest, cancelIsLoading } = useCancelMembership();

  const onPersonnelNumberChange = async (value: string) => {
    const employeeId = detailsData?.employee.id || '';
    await axios.put(
      `employees/${employeeId}/personnel-number`,
      JSON.stringify({ personnelNumber: value }),
      getRequestConfig(),
    );
  };

  const onEmployeeCancel = async () => {
    const membershipId = undefined === detailsData
      ? ''
      : detailsData.employee.membershipId;

    await cancelRequest(
      membershipId,
      selectedDate || null,
      reason,
      description || null,
    );

    toast(t('employees_page.details.date_popup.success_message'));

    pushDataLayerEvent(DATA_LAYER_EVENTS.membershipCancelationConfirmed);

    setCancellationStep(CancellationSteps.CLOSE);
    setReason('');
    setDescription('');
    setSelectedDate('');

    updateListHandler();
  };

  return (
    <>
      <Dialog
        title={
          !detailsLoading && detailsData
            ? `${detailsData.employee.firstName} ${detailsData.employee.lastName}`
            : ''
        }
        description=''
        open={isOpen}
        showFooter={false}
        titleVariant='close'
        onCancel={() => setIsOpen(false)}
      >
        <div className='hidden sm:flex gap-x-2'>
          {detailsData?.employee.membershipStateActions.includes(
            EmployeeStateActions.REJECT,
          ) && (
            <USCButton
              onClick={() => {
                if (detailsData)
                  void onEmployeeDecline(detailsData.employee.membershipId);
              }}
              disabled={statusChangeLoading}
              variant='danger-secondary'
              fullWidth
            >
              {t('employees_page.details.decline_button')}
            </USCButton>
          )}
          {detailsData?.employee.membershipStateActions.includes(
            EmployeeStateActions.APPROVE,
          ) && (
              <USCButton
                onClick={() => {
                  if (detailsData)
                    void onEmployeeApprove(detailsData.employee.membershipId);
                }}
                disabled={statusChangeLoading}
                className='mb-4'
                fullWidth
              >
                {t('employees_page.details.approve_button')}
              </USCButton>
            
          )}
        </div>
        {detailsLoading ? (
          'Loading…'
        ) : (
          <>
            <CellItem
              property={t('employees_page.details.personnel_number')}
              value={detailsData?.employee.personnelNumber}
              editable={true}
              onSave={onPersonnelNumberChange}
              fieldClassName='max-[400px]:max-w-[90px] max-[350px]:max-w-[50px]'
            />
            <Separator marginBottom={16} />
            <CellItem
              property={t('employees_page.details.email')}
              value={detailsData?.employee.email}
              className='max-sm:flex-col max-sm:items-start max-sm:pb-2'
            />
            <CellItem
              property={t('employees_page.details.city')}
              value={detailsData?.employee.membershipCityName}
              className='max-sm:flex-col max-sm:items-start'
            />
            <div className='pb-4'>
              <p className='text-base font-bold'>Membership Detail</p>
            </div>
            <CellItem
              property={t('employees_page.details.integration_state')}
              value={t(
                `employees_page.details.membership_states.${
                  detailsData?.employee.membershipState as string
                }`,
              )}
            />
            <CellItem
              property={t('employees_page.details.membership_number')}
              value={detailsData?.employee.membershipCardNumber}
            />
            <CellItem
              property={t('employees_page.details.plan_type')}
              value={detailsData?.employee.membershipPlanTypeName}
            />
            <CellItem
              property={t('employees_page.details.signup_date')}
              value={
                detailsData?.employee.membershipSignUpDate &&
                formatDate(new Date(detailsData?.employee.membershipSignUpDate))
              }
            />
            <CellItem
              property={t('employees_page.details.start_date')}
              value={
                detailsData?.employee.membershipStartDate &&
                formatDate(new Date(detailsData?.employee.membershipStartDate))
              }
            />
            <CellItem
              property={t('employees_page.details.end_date')}
              value={
                detailsData?.employee?.membershipCancellationDate &&
                formatDate(
                  new Date(detailsData?.employee.membershipCancellationDate),
                )
              }
            />
            <CellItem
              property={t('employees_page.details.notice_period')}
              value={detailsData?.employee.membershipMinimumPeriodMonths}
              className='pb-0'
            />
            {detailsData?.employee.membershipStateActions.includes(
              EmployeeStateActions.CANCEL,
            ) && (
              <USCButton
                onClick={() => {
                  setCancellationStep(CancellationSteps.REASON);
                  pushDataLayerEvent(
                    DATA_LAYER_EVENTS.membershipCancelationStarted,
                  );
                  setIsOpen(false);
                }}
                variant='danger-secondary'
                className='text-base mt-6'
                fullWidth
              >
                {t('employees_page.details.cancel_button')}
              </USCButton>
            )}
          </>
        )}
        <div className='flex gap-x-2 sm:hidden'>
          {detailsData?.employee.membershipStateActions.includes(
            EmployeeStateActions.REJECT,
          ) && (
            <USCButton
              onClick={() => {
                if (detailsData)
                  void onEmployeeDecline(detailsData.employee.membershipId);
              }}
              disabled={statusChangeLoading}
              variant='danger-secondary'
              className='mt-4'
              fullWidth
            >
              {t('employees_page.details.decline_button')}
            </USCButton>
          )}
          {detailsData?.employee.membershipStateActions.includes(
            EmployeeStateActions.APPROVE,
          ) && (
              <USCButton
                onClick={() => {
                  if (detailsData)
                    void onEmployeeApprove(detailsData.employee.membershipId);
                }}
                disabled={statusChangeLoading}
                className='mt-4'
                fullWidth
              >
                {t('employees_page.details.approve_button')}
              </USCButton>
            
          )}
        </div>
      </Dialog>
      <Popup
        heading={t('employees_page.details.reason_popup.title')}
        isOpen={cancellationStep === CancellationSteps.REASON}
      >
        {detailsData &&
          Object.keys(detailsData?.employee.membershipCancellationOptions).map(
            (option) => (
              <Radio
                key={option}
                id={option}
                value={option}
                name='reason'
                label={t(`employees_page.details.reason_popup.${option}`)}
                checked={reason === option}
                onChange={({ target }) => setReason(target.value)}
              />
            ),
          )}
        {reason === 'other' && (
          <Input
            name='description'
            type='text'
            placeholder='Other reason'
            onChange={({ target }) => setDescription(target.value)}
          />
        )}
        <div className='popup__buttons'>
          <Button
            onClick={() => setCancellationStep(CancellationSteps.CLOSE)}
            text={t('employees_page.details.reason_popup.cancel_button')}
            type={ButtonType.SECONDARY}
            size={ButtonSize.SMALL}
          />
          <CustomButton 
            onClick={() => {
              if (
                detailsData?.employee.membershipCancellationOptions[
                  reason as keyof EmployeeMembershipCancelationOptions
                ].length
              ) {
                setCancellationStep(CancellationSteps.DATE);
                pushDataLayerEvent(
                  DATA_LAYER_EVENTS.membershipCancelationReasonSelected,
                );
              } else {
                void onEmployeeCancel();
              }
            }}
            text={t('employees_page.details.reason_popup.continue_button')}
            size={CustomButtonSize.SMALL}
            colors={CustomButtonVariants.PRIMARY}
            disabled={!reason || (reason === 'other' && !description)}
            isLoading={cancelIsLoading}
          />
        </div>
      </Popup>
      <Popup
        heading={t('employees_page.details.date_popup.title')}
        isOpen={cancellationStep === CancellationSteps.DATE}
      >
        {detailsData &&
          reason &&
          detailsData?.employee.membershipCancellationOptions[
            reason as keyof EmployeeMembershipCancelationOptions
          ].map((date, i) => (
            <Radio
              key={date}
              id={`date-${i}`}
              value={date}
              name='date'
              checked={selectedDate === date}
              label={formatDate(new Date(date))}
              onChange={({ target }) => setSelectedDate(target.value)}
            />
          ))}
        <div className='popup__buttons popup__buttons--first-left'>
          <Button
            onClick={() => setCancellationStep(CancellationSteps.REASON)}
            text={t('employees_page.details.date_popup.go_back_button')}
            type={ButtonType.SECONDARY}
            size={ButtonSize.SMALL}
          />
          <Button
            onClick={() => setCancellationStep(CancellationSteps.CLOSE)}
            text={t('employees_page.details.date_popup.cancel_button')}
            type={ButtonType.SECONDARY}
            size={ButtonSize.SMALL}
            loading={cancelIsLoading}
          />
          <CustomButton 
            onClick={onEmployeeCancel}
            text={t('employees_page.details.date_popup.continue_button')}
            size={CustomButtonSize.SMALL}
            colors={CustomButtonVariants.PRIMARY}
            disabled={!selectedDate}
            isLoading={cancelIsLoading}
          />
        </div>
      </Popup>
    </>
  );
};

export default EmployeeDetails;
