import { Badge } from '@urbansportsclub/components/Badge';
import * as styles from './StatusCard.variants';
import { useRef, useState } from 'react';

export default function StatusCard({
  title,
  color,
  tooltipText,
  id,
} : {
  title: string,
  color: string,
  tooltipText: string,
  id: string,
}) {
  const badgeRef = useRef<HTMLSpanElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const showTooltip = () => {
    setIsTooltipVisible(true);
  };

  const hideTooltip = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onFocus={showTooltip}
      onBlur={hideTooltip}
    >
      <Badge variant={'secondary'} className='mx-4 cursor-pointer '>
        <span id={id} ref={badgeRef}>{title}</span>
        <span className={styles.circleVariant({ className: color })}/>
      </Badge>
      <div
        className={styles.tooltipVariant({ isVisible: isTooltipVisible })}
      >
        {tooltipText}
      </div>
    </div>
  );
}
