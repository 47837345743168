import { tv } from 'tailwind-variants';

export const cellItemVariant = tv({
  base: 'flex justify-between items-center pb-4',
  variants: {
    isEditable: {
      true: 'flex-col items-start',
      false: 'flex-row',
    },
  },
});

export const cellItemField = tv({
  base: 'text-base text-grey-dark-700 overflow-hidden whitespace-nowrap text-ellipsis',
});
