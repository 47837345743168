import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@urbansportsclub/components/Button';
import * as styles from './CellItem.variants';

interface Props {
  property?: string;
  value?: string;
  editable?: boolean;
  name?: string;
  onSave?: (param:any) => void
  className?: string;
  fieldClassName?: string;
}

const CellItem = ({ property, value, editable, onSave, className, fieldClassName }: Props): JSX.Element => {

  const [isEditable, setIsEditable] = useState(false);
  const [itemValue, setItemValue] = useState(value);

  function handleEdit() {
    setIsEditable(!isEditable);
  }

  function handleSave() {
    onSave && onSave(itemValue);
    setIsEditable(!isEditable);
    toast('Personnel number updated');
  }

  function renderViewField() {
    return itemValue ? <p className={styles.cellItemField({ className: fieldClassName })}>{itemValue}</p> : '-';
  }

  function renderEditableField() {
    if (isEditable) {
      return (
        <div className='flex w-[100%] gap-4 flex-col sm:flex-row'>
          {editableInputField()}
          <div className='flex gap-2'>
            <Button className='text-base flex-1 sm:flex-0' onClick={handleSave}>Save</Button>
            <Button className='text-base flex-1 sm:flex-0' variant='secondary' onClick={handleEdit}>Cancel</Button>
          </div>
        </div>
      );
    }

    return (
      <p className="flex items-center gap-x-4">
        {renderViewField()}
        <Button
          onClick={handleEdit}
          variant='secondary'
          className='text-sm'
          small
        >
          Edit      
        </Button>
      </p>
    );
  }

  function editableInputField() {
    return (
      <input
        className='border border-blue-respect-500 rounded-md flex-1'
        name="name"
        type="text"
        value={itemValue}
        onChange={(e) => setItemValue(e.target.value)}
      />
    );
  }

  return (
    <div className={ styles.cellItemVariant({ isEditable: isEditable, className: className }) }>
      <p className='text-base'>{property}</p>
      {editable
        ? renderEditableField() 
        : renderViewField()
      }
    </div>
  );
};

export default CellItem;
