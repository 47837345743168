import { tv } from 'tailwind-variants';

export const paginationArrow = tv({
  base: 'ring-1 ring-grey-dark-300 w-[40px] bg-white-natural-500',
  variants: {
    isDisabled: {
      true: 'bg-grey-natural-200',
    },
    isLeft: {
      true: 'rounded-l-md',
      false: 'rounded-r-md',
    },
  },
});

export const paginationItem = tv({
  base: 'text-base text-black-midnight-light ring-1 ring-grey-dark-300 bg-white-natural-500 focus:ring-blue-respect-500 focus:rounded-md cursor-pointer',
  variants: {
    isActive: {
      true: 'text-white !bg-blue-respect-500',
    },
    isFirst: {
      true: 'rounded-l-md',
    },
    isLast: {
      true: 'rounded-r-md',
    },
  },
});
