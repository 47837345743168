import { tv } from 'tailwind-variants';

export const circleVariant = tv({
  base: 'inline-block ml-1 w-[6px] h-[6px] rounded-full relative bottom-0',
});

export const tooltipVariant = tv({
  base: 'max-w-[200px] text-wrap absolute bg-black-midnight-light-500 text-grey-natural-50 text-base px-4 py-2 rounded-md ml-[110px] mt-[-45px] after:absolute after:w-[10px] after:h-[10px] after:bg-black-midnight-light-500 after:left-[-5px] after:top-1/2 after:transform after:-translate-y-1/2 after:rotate-45',
  variants: {
    isVisible: {
      true: 'inline',
      false: 'hidden',
    },
  },
});
